import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppBtn from './components/WhatsAppBtn';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Gite from './pages/Gite';
// import DurataPrezzi from './pages/DurataPrezzi';
import Contatti from './pages/Contatti';
import CookiePolicy from './pages/CookiePolicy';
import ErrorPage from './pages/ErrorPage';

export default function App() {
	const location = useLocation();
	useEffect(() => {
		!location.hash ?
			setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0) :
			localStorage.removeItem('position');
	}, [location]);

	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const load = setTimeout(()=>setIsLoading(false), 1200);
		return () => clearTimeout(load);
	}, [])
	
	if(isLoading) return(
		<motion.div
			className="loading"
			initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: .2, ease: 'easeInOut' }}
         exit={{ opacity: 0 }}
		>
			<PuffLoader color="#febc28" speedMultiplier="2" />
		</motion.div>
	);

	return (
		<AnimatePresence>
			<Header />
			
			<main>
				<Routes location={location} key={location.key}>
					<Route path="/" element={<Home />} />
					<Route path=":tripName" element={<Gite />} />
					<Route path="contatti" element={<Contatti />} />
					<Route path="cookie-policy" element={<CookiePolicy />} />
					<Route path='/*' element={<ErrorPage />} />
				</Routes>
			</main>

			<Footer />

			<WhatsAppBtn />
			<ScrollToTop />
		</AnimatePresence>
	);
}