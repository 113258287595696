import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../imgs/logo.svg';

function Footer() {
   const dateYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <div className="grid gtc-3 g-2 align-items-start">
            <div>
               <h3>Chi Siamo</h3>
               <p>Regalati una giornata indimenticabile in un luogo magico dove la natura urla tutta la sua bellezza.</p>
               <img src={logo} className="pb-2" alt="Sardegna Cruiser logo" />
            </div>

            <div>
               <h3>Contatti</h3>
               <ul>
                  <li><a href="https://www.google.com/maps?ll=39.207183,9.112396&z=14&t=m&hl=it-IT&gl=US&mapclient=embed&q=Molo+Dogana,+Via+Roma,+09125+Cagliari+CA" target="_blank"><address>Molo Dogana, Via Roma, 09125 Cagliari CA</address></a></li>
                  <li>Cell: <a href="tel:3357293592">+39 335 7293592</a></li>
                  <li>Email: <a href="mailto:sardegnacruiser@gmail.com">sardegnacruiser@gmail.com</a></li>
               </ul>
            </div>

            <div>
               <h3>Seguici su:</h3>
               <ul className="flex flex-col g-1">
                  <li>
                     <a href="https://www.facebook.com/profile.php?id=100070208015684" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg>
                     </a>
                  </li>
                  <li>
                     <a href="https://www.instagram.com/sardegnacruiser" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                     </a>
                  </li>
               </ul>
            </div>
         </div>
         
         <p className="txt-center pt-3 pb-2">&copy; Sardegna Cruiser {dateYear}. P.Iva 04016860928. <a href="https://www.iubenda.com/privacy-policy/67910438/legal" target="_blank" rel="noreferrer">Privacy policy</a> - <Link to="/cookie-policy">Cookie policy</Link>. <br />Developed by <a href="https://milanesieditore.it" target="_blank" rel="noreferrer">Milanesi Editore</a>.</p>
      </motion.footer>
   );
}

export default Footer;