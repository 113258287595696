import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ImgSlider from '../components/ImgSlider';
import ReservationForm from '../components/ReservationForm';
import ImgCarousel from '../components/ImgCarousel';

import barca from '../imgs/barca.jpg';
import giunco from '../imgs/giunco.jpg';
import diavolo from '../imgs/diavolo.jpg';
import bandiera from '../imgs/bandiera.webp';
import isole from '../imgs/isole.webp';
import molentis from '../imgs/molentis.webp';
import fighera from '../imgs/fighera.jpg';
import poleto from '../imgs/poleto.jpg';
import calamosca from '../imgs/calamosca.jpg';
import cuccureddu from '../imgs/cuccureddu.jpg';
import cavoli from '../imgs/cavoli.jpg';
import arcipelago from '../imgs/arcipelago-spiaggia.jpg';
import arcipelagoRoccioso from '../imgs/arcipelago-spiaggia-rocciosa.jpg';
import poppaBarca from '../imgs/poppa-barca.jpg';
import pranzoBarca from '../imgs/pranzo-barca.jpg';
import aperitivo from '../imgs/aperitivo.jpg';
import maps from '../imgs/maps.png';
import cagliarimaps from '../imgs/cagliari-maps.webp';


export default function Home() {
	return (
      <>
         <Helmet>
            <title>Sardegna Cruiser</title>
            <meta name="description" content="Regalati una giornata indimenticabile in un luogo magico dove la natura urla tutta la sua bellezza con la barca a vela Sardegna Cruiser" />
         </Helmet>

         <ImgSlider slides={[ bandiera, isole, molentis ]}>
            <motion.h1
               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .7, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
               >Sardegna Cruiser</motion.h1>
            <ReservationForm />
         </ImgSlider>


         <section id="barca" className="pt-6 pb-6">
            <div className="container grid align-items-start gtc-1-2 g-2">
               <div className="fs-md txt-right">
                  <p>Noleggia una barca a vela e scopri la natura incontaminata del Sud Sardegna.</p>
                  
                  <img src={maps} className="mbk-2" alt="Cartina - Sardegna Cruiser" />
                  
                  <p className="f-serif fs-md txt-primary"><i>Regalati una giornata indimenticabile in un luogo magico dove la natura urla tutta la sua bellezza.</i></p>
               </div>

               <div>
                  <p><span className="dropcap">G</span><small>iro di Boa è un Bavaria 44, barca a vela ideale per una crociera in famiglia, il confort e la manovrabilità sono i tratti distintivi di questa barca. Va veloce e va lontano.</small></p>
                  
                  <p>Sardegna Cruiser organizza la tua crociera, ascoltando le tue esigenze, esaudendo i tuoi desideri. Crociere in esclusiva e personalizzate o condivise con altri ospiti per una capienza massima di 8/9 persone.</p>
                  
                  <div className="grid g-2 mbk-3">
                     <img src={poppaBarca} alt="Crociera: la barca a vela di Sardegna Cruiser" />
                     <img src={pranzoBarca} alt="Pranzo a bordo della barca a vela di Sardegna Cruiser" />
                  </div>

                  <p>Offriamo varie opportunità di noleggio con o senza skipper, intera o mezza giornata, più giorni con pernottamento a bordo, in esclusiva o condiviso con altri ospiti e tanti altri servizi per rendere il tuo soggiorno magico.</p>
               </div>
            </div>
         </section>

         <section id="noleggio" className="grid gtc-2-1 g-2 pb-2">
            <ImgCarousel
               slides={[ barca, diavolo, giunco ]}
               btnSide="right"
               altText="Gita giornaliera - Sardegna Cruiser"
            />

            <div className="box-light">
               <h2>Gita giornaliera</h2>
               <p>Vivi una giornata di sole, mare, luce. Scopri le bellezze del mare di Cagliari: Cala Mosca, Cala Fighera, la Grotta dei Colombi e altre incantevoli destinazioni per nuotare in meravigliose piscine naturali. Goditi un pranzo a bordo degustando tipici piatti sardi di carne e pesce.</p>
               <Link to="/gita-giornaliera" className="btn">Scopri ora +</Link>
            </div>
         </section>

         <section className="grid gtc-1-2 g-2 pb-2">
            <ImgCarousel
               slides={[ fighera, poleto, calamosca ]}
               btnSide="left"
               altText="Gita mezzagiornata - Sardegna Cruiser"
            />

            <div className="box-light order-1">
               <h2>Gita mezzagiornata</h2>
               <p>Sali a bordo, scoprirai luoghi meravigliosi per tuffarti nelle limpide acque del mare di Sardegna circondato solo da natura e cielo.</p>
               <Link to="/gita-mezzagiornata" className="btn">Scopri ora +</Link>
            </div>
         </section>

         <section className="grid gtc-2-1 g-2 pb-2">
            <ImgCarousel
               slides={[ aperitivo ]}
               btnSide="right"
               altText="Aperitivo al tramonto - Sardegna Cruiser"
            />

            <div className="box-light">
               <h2>Aperitivo al tramonto</h2>
               <p>Un aperitivo al tramonto tra mare e cielo degustando prodotti tipici sardi accompagnati da calici di ottimo vino. Vive questa esperienza in compagnia.</p>
               <Link to="/aperitivo-al-tramonto" className="btn">Scopri ora +</Link>
            </div>
         </section>

         <section className="grid gtc-1-2 g-2 pb-6">
            <ImgCarousel
               slides={[ cuccureddu, cavoli, arcipelago, arcipelagoRoccioso ]}
               btnSide="left"
               altText="Noleggio settimanale - Sardegna Cruiser"
            />

            
            <div className="box-light order-1">
               <h2>Noleggio settimanale</h2>
               <p>Scegli di vivere una vacanza di vero mare in barca a vela scoprendo luoghi meravigliosi ed unici nel fantastico mare del Sud Sardegna. Offriamo confort e servizi per rendere il tuo soggiorno indimenticabile.</p>
               <Link to="/noleggio-settimanale" className="btn">Scopri ora +</Link>
            </div>
         </section>

         <section id="itinerari" className="pb-6">
            <img src={cagliarimaps} alt="Itinerario maps - Sardegna Cruiser" />
         </section>
      </>
	);
}