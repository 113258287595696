import { Helmet } from "react-helmet";
import ReservationForm from "../components/ReservationForm";

export default function Contatti() {
   return (
      <section className="container pt-6 pb-6">
         <Helmet>
            <title>Contatti - Sardegna Cruiser</title>
            <meta  name="description" content="Con Sardegna Cuisers puoi noleggiare una barca a vela e scoprire l'arcipelago di La Maddalena: contattaci ora!" />
         </Helmet>
         
         <h1 className="pb-2">Contatti</h1>

         <ReservationForm />

         <iframe title="Cagliari Maps" src="https://www.google.com/maps?q=Molo+Dogana,+Via+Roma,+09125+Cagliari+CA&t=&z=14&ie=UTF8&iwloc=&output=embed" />
      </section>
   );
}