import { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import  emailjs from '@emailjs/browser';

function ReservationForm() {
   const form = useRef();
   const initValue = { name:'', email: '', tel: '', date: '', trip:'', message:'' };
   const [formVals, setFormVals] = useState(initValue),
         [formErrs, setFormErrs] = useState({}),
         [isSubmit, setIsSubmit] = useState(false),
         [isSent, setIsSent] = useState(false);

   const handleChange = e => {
      const { id, value } = e.target;
      setFormVals({ ...formVals, [id]: value });
   };
   
   const handleSubmit = e => {
      e.preventDefault();
      setFormErrs(validate(formVals));
      setIsSubmit(true);
   }

   useEffect(() => {
      if(Object.keys(formErrs).length === 0 && isSubmit) {
         emailjs.sendForm('service_vicd677', 'template_dyp0fxs', form.current, 'q7TBo1VikWhJYuKGB')
            .then(result => {
               console.log(result.text);
               setFormVals(initValue);
               setIsSent(true);
               setFormErrs({});
               setIsSubmit(false);
            }, error => {
               console.log(error.text);
               setIsSubmit(false);
               setIsSent(false);
            }
         );
      }
   }, [formErrs, isSubmit]);
   

   const validate = (vals) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
            phoneRegex= /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      
      if (!vals.name) {
         errors.name = 'Il nome è obbligatorio!';
      } else if (vals.name.length < 4) {
         errors.name = 'Inserisci almeno 4 caratteri!';
      }

      if (!vals.email) {
         errors.email = 'L\'email è obbligatoria!';
      } else if (!emailRegex.test(vals.email)) {
         errors.email = 'Questo non è un formato email valido!';
      }

      if (!vals.tel) {
         errors.tel = 'Il telefono è obbligatorio!';
      } else if (!phoneRegex.test(vals.tel)) {
         errors.tel = 'Questo non è un formato valido!';
      }

      if (!vals.date) {
         errors.date = 'Seleziona una data';
      }

      if (!vals.trip) {
         errors.trip = 'Seleziona gita';
      }
      
      return errors;
   };

   return (
      <motion.form
         ref={form}
         onSubmit={handleSubmit}

         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <input
            id="name"
            name="name"
            type="text"
            placeholder="Nome Completo"
            required
            value={formVals.name}
            onChange={handleChange}
         />
         {formErrs.name &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.name}</h4>
         }

         <input
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            required
            value={formVals.email}
            onChange={handleChange}
         />
         {formErrs.email &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.email}</h4>
         }

         <input
            id="tel"
            name="tel"
            type="tel"
            placeholder="Telefono"
            required
            value={formVals.tel}
            onChange={handleChange}
         />
         {formErrs.tel &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.tel}</h4>
         }

         <input
            id="date"
            name="date"
            type="date"
            value={formVals.date}
            onChange={handleChange}
         />
         {formErrs.date &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.date}</h4>
         }

         <select
            id="trip"
            name="trip"            
            value={formVals.trip}
            onChange={handleChange}
         >
            <option defaultValue>--Seleziona--</option>
            <option value="Gita giornaliera">Gita giornaliera</option>
            <option value="Gita mezzagiornata">Gita mezzagiornata</option>
            <option value="Gite giornaliera in esclusiva">Gite giornaliera in esclusiva</option>
            <option value="Gita mezza giornata in esclusiva">Gita mezza giornata in esclusiva</option>
            <option value="Aperitivo al tramonto">Aperitivo al tramonto</option>
            <option value="Noleggio settimanale">Noleggio settimanale</option>
         </select>
         {formErrs.trip &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.trip}</h4>
         }

         <textarea
            id="message"
            name="message"
            rows="6"
            placeholder="Messaggio"
            value={formVals.message}
            onChange={handleChange}
         />

         {isSent &&
            <motion.h3
               className="txt-center txt-success"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >Messaggio inviato!</motion.h3>
         }

         {Object.keys(formErrs).length !== 0 &&
            <h3
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >Errore! Controlla i campi.</h3>
         }

         <input type="submit" value="Invia ora"/>
      </motion.form>
   );
}

export default ReservationForm;