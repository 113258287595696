import { Helmet } from "react-helmet";
import { useParams  } from 'react-router-dom';
import tripList from '../data';
import ReservationForm from "../components/ReservationForm";
import ErrorPage from './ErrorPage';
import porto from '../imgs/porto.jpg';


export default function Gite() {
   const { tripName } = useParams(),
			trip = tripList.filter(trip => trip.slug.includes(tripName))[0];

	if (trip) {
		return (
			<>
				<Helmet>
					<title>{trip.name} - Sardegna Cruiser</title>
				</Helmet>

				<section className="pb-6">
					<img src={porto} className="headtrip" alt="Cagliari - Sardegna Cruiser" />

					<div className="container grid gtc-2-1 g-2 align-items-start pt-6">
						<div className="pb-2">
							<h2 className="pb-2">{trip.name}</h2>
							{trip.content}
						</div>

						<aside>
							<ReservationForm />

							{/* tripList.map(trip =>
								trip.slug !== tripName &&
									<Link to={`/${trip.slug}`} key={trip.slug}>
										<h3>{trip.name}</h3>
									</Link>
							) */}
						</aside>
					</div>
				</section>
			</>
		);
	} else {
		return <ErrorPage />
	}
}