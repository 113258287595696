function WhatsAppBtn() {
   return (
      <a
         id="wachat"
         href="https://wa.me/393357293592?text=Buongiorno+Sardegna+Cruiser,+avrei+bisogno+di:+"
         target="_blank"
         rel="noreferrer"
      >
         <p className="wamessage">Ciao, Possiamo aiutarti?</p>
      </a>
   );
}

export default WhatsAppBtn;