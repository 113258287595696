import { Helmet } from "react-helmet";
import useScript from '../hooks/useScript';

export default function CookiePolicy() {
   useScript('https://consent.cookiebot.com/09c08c88-ece2-4829-bc0a-b2249cd38a0e/cd.js');
   
   return (
      <section className="container pt-6 pb-6">
         <Helmet>
            <title>Cookie Policy - Sardegna Cruiser</title>
         </Helmet>
         
         <h1>Cookie Policy</h1>

         <div id="CookieDeclaration" />
      </section>
   );
}