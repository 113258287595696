import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { motion } from 'framer-motion';
import Weather from './Weather';
import logo from '../imgs/logo.svg';

function Header() {
   const refNavBar = useRef(),
         [isOpen, setIsOpen] = useState(false),
         handleToggle = () => setIsOpen(!isOpen);    

   return (
      <motion.header
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: .5, ease: 'easeInOut' }}
         exit={{ opacity: 0 }}
      >
         <Link to="/" className="w-100">
            <img src={logo} className="logo" alt="Sardegna Cruiser logo" />
         </Link>
         
         <button
				className="nav-toggle"
				aria-controls={refNavBar}
				aria-expanded={isOpen}
				onClick={handleToggle}
         >
            <span className="sr-only">Menu</span>
         </button>
         <nav
            id="navbar"
            ref={refNavBar}
            data-visible={isOpen}
            onFocus={handleToggle}
         >
            <ul className="flex g-3">
               <li><HashLink to="/">Home</HashLink></li>
               <li><HashLink to="/#barca">Giro di boa</HashLink></li>
               <li><HashLink to="/#noleggio">Noleggio</HashLink></li>
               <li><HashLink to="/#itinerari">Itinerari</HashLink></li>
               <li><HashLink to="contatti">Contatti</HashLink></li>
            </ul>
         </nav>
         
         <Weather />
      </motion.header>
   );
}

export default Header;