import { useState, useEffect, useCallback } from 'react';

function ImgCarousel({ slides, btnSide, altText }) {
   const [current, setCurrent] = useState(0),
         length = slides.length,
         time = 4000;

   const handleNext = useCallback(() => {
      current + 1 >= length ?
         setCurrent(0) :
         setCurrent(current + 1);
   }, [current, length]);
   
   const handlePrev = () => {
      current === 0 ?
         setCurrent(length - 1) :
         setCurrent(current - 1);
   };

   useEffect(() => {
      const autoplay = setInterval(handleNext, time);
      return () => clearInterval(autoplay);
   }, [handleNext]);

   return (
      <div className={"carousel btn-side-" + btnSide}>
         <img src={slides[current]} alt={altText} />

         {length > 1 &&
            <>
               <button className="prev" onClick={handlePrev}>
                  <span className="sr-only">Slide Precedente</span>
               </button>

               <button className="next" onClick={handleNext}>
                  <span className="sr-only">Slide Successiva</span>
               </button>
            </>
         }
      </div>
   );
}

export default ImgCarousel;