export default [
	{
		slug: 'gita-giornaliera',
		name: 'Gita Giornaliera',
		content: <>
			<h3>Partenza</h3>
			<p>Alle ore 09:00 dal Molo Dogana del Porto di Cagliari in Via Roma. Rientro in porto alle ore 18:00.</p>
			<h3>Destinazione</h3>
			<p>Cala Mosca, Cala Fighera, Grotta dei Colombi, Piscine di Gasole (Sella del Diavolo), Poetto, Cala Regina, Mari Pintau.</p>
			<h3>Cosa offriamo</h3>
			<p>Crostini di pesce e/o tagliere di formaggi e salumi sardi, pane carasau, spaghetti alla bottarga o in alternativa al sugo o pesto, frutta di stagione, accompagnato da bevande analcoliche, acqua, vino.<br /><b>Su richiesta si effettuano pranzi personalizzati con prezzo e menù da concordare almeno 24 ore prima della partenza.</b></p>
			<h3>Barca e Servizi</h3>
			<ul className="list">
				<li>3 cabine matrimoniali + 1 con letti a castello</li>
				<li>2 bagni</li>
				<li>Zona confort</li>
				<li>Tv e musica, diffusione musicale sia interna che esterna. TV in dinette</li>
				<li>Frigo</li>
				<li>Attrezzatura snorkeling</li>
				<li>Tender</li>
			</ul>
			<h3>Prezzi</h3>
			<p>Giornaliera con pranzo <b>€ 100</b> per persona, min 6 max 8<br />Giornata intera in esclusiva con pranzo con <b>€ 800</b><br />Maxi escursione Villasimius con pranzo <b>€ 1100</b></p>
			<h3>Costi aggiuntivi</h3>
			<p>Teli mare € 10<br />Biancheria € 25 cad</p>
		</>
	},
	{
		slug: 'gita-mezzagiornata',
		name: 'Gita Mezzagiornata',
		content: <>
			<h3>Mattina</h3>
			<p>Partenza alle ore 09:00 dal Molo Dogana del Porto di Cagliari in Via Roma. Rientro in porto alle ore 13:00.</p>
			<h3>Pomeriggio</h3>
			<p>Partenza alle ore 14:00 dal Molo Dogana del Porto di Cagliari in Via Roma. Rientro in porto alle ore 18:00.</p>
			<h3>Destinazione</h3>
			<p>Cala Mosca, Cala Fighera, Grotta dei Colombi, Piscine di Gasole (Sella del Diavolo), Poetto.</p>
			<h3>Cosa offriamo</h3>
			<p>Tagliere di formaggi e salumi sardi, pane carasau accompagnato da bevande analcoliche, acqua, vino.<br /><b>Su richiesta organizziamo aperitivo personalizzato a bordo con prezzo e menù da concordare almeno 24 ore prima della partenza.</b></p>
			<h3>Barca e Servizi</h3>
			<ul className="list">
				<li>3 cabine matrimoniali + 1 con letti a castello</li>
				<li>2 bagni</li>
				<li>Zona confort</li>
				<li>Tv e musica, diffusione musicale sia interna che esterna. TV in dinette</li>
				<li>Frigo</li>
				<li>Attrezzatura snorkeling</li>
				<li>Tender</li>
			</ul>
			<h3>Prezzi</h3>
			<p>Mezza giornata <b>€ 70</b> per persona, min 6 max 8<br />Mezza giornata in esclusiva <b>€ 500</b></p>
			<h3>Costi aggiuntivi</h3>
			<p>Teli mare € 10<br />Biancheria € 25 cad</p>
		</>
	},
	{
		slug: 'aperitivo-al-tramonto',
		name: 'Aperitivo al tramonto',
		content: <>
			<h3>Partenza</h3>
			<p>Alle ore 18:00 dal Molo Dogana del Porto di Cagliari in Via Roma. Rientro in porto alle ore 21:00.</p>
			<h3>Destinazione</h3>
			<p>Cala Mosca, Cala Fighera, Grotta dei Colombi, Piscine di Gasole (Sella del Diavolo), Poetto.</p>
			<h3>Cosa offriamo</h3>
			<p>Tagliere di formaggi e salumi sardi, pane carasau, accompagnato da bevande analcoliche, acqua, vino.<br /><b>Su richiesta organizziamo aperitivo personalizzato a bordo con prezzo e menù da concordare almeno 24 ore prima della partenza.</b></p>
			<h3>Barca e Servizi</h3>
			<ul className="list">
				<li>3 cabine matrimoniali + 1 con letti a castello</li>
				<li>2 bagni</li>
				<li>Zona confort</li>
				<li>Tv e musica, diffusione musicale sia interna che esterna. TV in dinette</li>
				<li>Frigo</li>
				<li>Attrezzatura snorkeling</li>
				<li>Tender</li>
			</ul>
			<h3>Prezzi</h3>
			<p>Aperitivo al tramonto <b>€ 70</b> per persona, min 6 max 8<br />Aperitivo al tramonto in esclusiva <b>€ 500</b></p>
			<h3>Costi aggiuntivi</h3>
			<p>Teli mare € 10<br />Biancheria € 25 cad</p>
		</>
	},
	{
		slug: 'noleggio-settimanale',
		name: 'Noleggio Settimanale',
		content: <>
			<p>Check in 09:00 - check out 18:00</p>
			<h3>Prezzi</h3>
			<ul className="list">
				<li>01/01 - 25/03 <b>€ 1900</b></li>
				<li>26/03 - 03/06 <b>€ 2400</b></li>
				<li>04/06 - 22/07 <b>€ 2700</b></li>
				<li>23/07 - 19/08 <b>€ 4200</b></li>
				<li>20/08 - 22/09 <b>€ 2700</b></li>
				<li>23/09 - 11/11 <b>€ 1900</b></li>
			</ul>
			<h3>Costi aggiuntivi</h3>
			<ul className="list">
				<li><b>Skipper (obbligatorio)</b>: € 150 al giorno</li>
				<li><b>Gasolio</b>: Calcolato in base al consumo effettivo durante il noleggio</li>
				<li><b>Biancheria</b>: € 150 (se richiesta)</li>
				<li><b>Cambusa</b>: Costo variabile in base alle scelte del cliente</li>
				<li><b>Hostess di Bordo</b>: €100 al giorno (se richiesta)</li>
				<li><b>Spese Extra</b>: Qualsiasi altra spesa non inclusa sopra, come ormeggio in porti non convenzionati, tasse locali, ecc.</li>
			</ul>
			<h3>Condizioni</h3>
			<ul className="list">
				<li>I costi aggiuntivi sono da aggiungere al noleggio di base.</li>
				<li>I costi del gasolio saranno calcolati e pagati al termine del noleggio in base al consumo effettivo.</li>
				<li>La biancheria, la cambusa e l'hostess di bordo devono essere richieste al momento della prenotazione.</li>
				<li>Eventuali spese extra saranno dettagliate al momento del check-out.</li>
			</ul>
		</>
	}
]